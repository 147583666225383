<aside
  class="col-12 col-xs-12 col-sm-5 col-md-4 right-navigation float-right my-acc-aside"
>
  <div class="c-widget d-sm-flex">
    <h3 class="c-widget-title c-heading c-heading-h3">My Account</h3>
    <div class="c-widget-divider"></div>
    <div class="d-flex justify-content-between">
      <div class="c-widget--my-account-description">
        <img
          src="/../../../assets/icons/user-account.svg"
          alt="user account"
        />&nbsp;{{ 'accountOverview.account_label' | cxTranslate }} &nbsp;
        <strong>{{ accountNumber }}</strong>
      </div>
      <div>
        <a (click)="navigateToAccountDetails()" class="c-link"> {{ 'accountOverview.view_label' | cxTranslate }} </a>
      </div>
    </div>
  </div>
  <app-order-products></app-order-products>
  <section class="displayNo banner-Align"></section>
</aside>
